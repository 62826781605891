<template>
  <div id="app-header">
    <v-navigation-drawer v-model="sideNav" fixed temporary color="#fffcf5">
      <v-layout wrap justify-center  v-if="role == 'Admin'">
        <v-flex xs12 v-for="(item, i) in navItems" :key="i" text-center pa-2 pl-0 text-none align-self-center
         >
          <v-layout wrap justify-center>
            <v-flex xs12 px-0 pl-2 align-self-center text-left>
              <router-link style="text-decoration: none" :to="item.route">
                <v-icon>{{ item.icon }}</v-icon>
                <span :style="
                    $route.path == item.route
                      ? {
                          'font-family': 'robotoregular',
                        }
                      : {
                          'font-family': 'robotoregular',
                        }
                  " style="
                    font-size: 15px;
                    color: black;
                    letter-spacing: 1px;
                    cursor: pointer;
                  ">
                  {{ item.name }}
                </span>
              </router-link>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 text-center pa-2 pl-0 text-none align-self-center
        >
        <v-layout wrap justify-center>
          <v-flex xs12 px-0 pl-3 align-self-center text-left>
          <span style="
              font-size: 15px;
                    color: black;
                    letter-spacing: 1px;
                    cursor: pointer;
                  " @click="appLogout()">Logout</span>
                  </v-flex>
                  </v-layout>
     
      </v-flex>
      </v-layout>
      <v-layout wrap justify-center  v-else>
        <v-flex xs12 v-for="(item, i) in navItemsOne" :key="i" text-center pa-2 pl-0 text-none align-self-center
         >
          <v-layout wrap justify-center>
            <v-flex xs12 px-0 pl-2 align-self-center text-left>
              <router-link style="text-decoration: none" :to="item.route">
                <v-icon>{{ item.icon }}</v-icon>
                <span :style="
                    $route.path == item.route
                      ? {
                          'font-family': 'robotoregular',
                        }
                      : {
                          'font-family': 'robotoregular',
                        }
                  " style="
                    font-size: 15px;
                    color: black;
                    letter-spacing: 1px;
                    cursor: pointer;
                  ">
                  {{ item.name }}
                </span>
              </router-link>
            </v-flex>
           
          </v-layout>
        </v-flex>
        <v-flex xs12 text-center pa-2 pl-0 text-none align-self-center
        >
        <v-layout wrap justify-center>
          <v-flex xs12 px-0 pl-3 align-self-center text-left>
          <span style="
              font-size: 15px;
                    color: black;
                    letter-spacing: 1px;
                    cursor: pointer;
                  " @click="appLogout()">Logout</span>
                  </v-flex>
                  </v-layout>
     
      </v-flex>
      </v-layout>
    </v-navigation-drawer>

    <v-app-bar app dark grad dense flat height="50px" class="hidden-lg-and-up grad">
      <v-app-bar-nav-icon @click.native="sideNav = !sideNav">
        <v-icon color="white">mdi-menu</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title>
        <router-link style="text-decoration: none" to="/">
          <v-layout wrap justify-center>
            <v-flex xs12>
              <span style="
                  font-family: poppinsbold;
                  font-size: 14px;
                  cursor: pointer;
                  color: #ffffff;
                ">
                OLE Admin Management Portal
              </span>
            </v-flex>
           
          </v-layout>
        </router-link>
      </v-toolbar-title>
    </v-app-bar>
    <v-app-bar elevation="0" height="70px" grad class="hidden-sm-and-down grad">
      <v-layout wrap justify-center fill-height>
        <!-- <v-flex xs1 pl-7 pt-1 text-center> -->
        <!-- <v-img width="60px" src="../../assets/images/logo.png"></v-img>
        </v-flex> -->
        <v-flex xs2 align-self-center>
          <router-link style="text-decoration: none" to="/">
            <v-img src="../../assets/Images/OleLogo2.png"> </v-img>
          </router-link>
        </v-flex>
        <v-flex xs6 align-self-center>
          <span style="
            font-family: poppinsmedium;
            font-size: 14px;
            cursor: pointer;
            color: #000000;
          "> - Admin Management Portal</span>

        </v-flex>

        <v-spacer></v-spacer>
        <v-flex xs6 lg1 xl1 px-4 text-center align-self-center>
          <v-flex xs6 lg6 pt-2 sm6>
            <v-btn outlined color="black" class="opensansregular" small @click="appLogout()">
              <v-icon color="white" size="19">
                mdi-account-circle-outline
              </v-icon>
              <span style="
                  font-family: opensanssemibold;
                  color: black;
                  font-size: 12px;
                ">Logout</span>
            </v-btn>
          </v-flex>
        </v-flex>
      </v-layout>
    </v-app-bar>
  </div>
</template>
<script>
  import AccountMenu from "@/components/Common/accountMenu";
  export default {
    components: {
      AccountMenu,
    },
    data() {
      return {
        sideNav: false,
        navItems: [
          {
            name: "Dashboard",
            route: "/DashboardPage",
          },
          {
            name: "Introduction Video",
            route: "/appDashboard",
          },
          {
            name: "Manage Subscription Plans",
            route: "/PlansPage",
          },
          {
            name: "Plan Presentation Video",
            route: "/PlanPresentationVidoePage",
          },
          { name: "Active Users", route: "/ActiveUsersPage" },
          { name: "Pending Users", route: "/PendingUsersPage" },
          { name: "Rejected Users", route: "/RejectedUsersPage" },
          { name: "Video Categories", route: "/categoryPage" },
          {
            name: "Add Campaigns",
            route: "/CampaignPage",
          },
          {
            name: "Add Campaign Intro Video",
            route: "/CampaignIntroVideoPage",
          },

          { name: "Promocodes", route: "/PromoCodePage" },
          { name: "Training Videos", route: "/TrainingVideoPage" },
          { name: "Manage Store Managers", route: "/StoreManagerPage" },


          {
            name: "Manage Products",
            route: "/ProductsPage",
          },
          {
            name: "Manage Product Types",
            route: "/ProductTypePage",
          },
          {
            name: "Manage Product Categories",
            route: "/ProductCategoryPage",
          },


          {
            name: "Manage Orders",
            route: "/PurchasePage",
          },
          {
            name: "Set Delivery Charge",
            route: "/Delivery",
          },

          { name: "Terms and Conditions", route: "/TermsAndConditionPage" },
          { name: "Privacy Policy", route: "/PrivacyPolicyPage" },
        ],
        navItemsOne: [
        {
            name: "Dashboard",
            route: "/DashboardPage",
          },
        {
            name: "Manage Products",
            route: "/ProductsPage",
          },
          {
            name: "Manage Product Types",
            route: "/ProductTypePage",
          },
          {
            name: "Manage Product Categories",
            route: "/ProductCategoryPage",
          },


          {
            name: "Manage Orders",
            route: "/PurchasePage",
          },
        ],
      };
    },
    computed: {
      appUser() {
        return null;
      },
      appLogin() {
        return false;
      },
      appType() {
        return this.$store.state.userType;
      },
    
      role() {
        return this.$store.state.role;
      },
      appNavItems() {
        return this.navItems;
      },
      // appNavItemsOne() {
      //   return this.navItemsOne;
      // },
    },
    methods: {
      appLogout() {
        this.$store.commit("logoutUser", true);
      },
    },
  };
</script>
<style>
  input::-webkit-input-placeholder {
    color: #c5c5c5 !important;
    font-size: 14px !important;
    font-family: poppinsregular !important;
  }

  input::-moz-placeholder {
    color: #c5c5c5 !important;
    font-size: 14px !important;
    font-family: poppinsregular !important;
  }

  .searchBox .v-input__control {
    min-height: 10px !important;
  }
  /* #app-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  background-color: #333;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
} */
  .searchBox .v-input__control .v-input__slot {
    padding: 0px !important;
  }

  
</style>