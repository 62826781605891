import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from '../router'
Vue.use(Vuex)
export default new Vuex.Store({
    getters: {
    },
    state: {
        role: localStorage.getItem("role"),
        isLoggedIn: !!localStorage.getItem("token"),
        userData: {},
        activeusersfilter: {},
        planfilter: {},
    },
    mutations: {
        userData(state, item) {
            state.userData = item
        },
        logoutUser(state) {
            axios({
                method: "GET",
                url: "/logout",
                headers: {
                    "token": localStorage.getItem("token")
                }
            }).then(response => {
                if (response.data) {
                    state.isLoggedIn = false
                    state.userData = {}
                    state.initial = "";
                    localStorage.removeItem("token");
                    router.push('/')
                }
            })
        },
        role(state,item){
            state.role = item
        },
        loginUser(state, payload) {
            localStorage.setItem("token", payload);
            state.isLoggedIn = true
        },
        activeusersfilter(state,item){
            if(item.userType)
            localStorage.setItem("usertypes",item.userType)
            else
            localStorage.removeItem("usertypes")
            if(item.plan)
            localStorage.setItem("plans",item.plan)
            else
            localStorage.removeItem("plans")
            if(item.from)
            localStorage.setItem("froms",item.from)
            else
            localStorage.removeItem("froms")
            if(item.to)
            localStorage.setItem("tos",item.to)           
            else
            localStorage.removeItem("tos")
            state.activeusersfilter=item
            if(item.currentpage)
            localStorage.setItem("currentpages",item.currentpage)
            localStorage.removeItem("currentpages")
            state.activeusersfilter=item
        }
    },
    actions: {
    },
    modules: {
    }
})
