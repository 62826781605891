<template>
 <v-app>
      <router-view />
    </v-app>
</template>

<script>
import "./assets/style/font.css";
import "./assets/style/style.css";
import AppHeader from "./components/Common/appHeader";
export default {
  name: "App",
  AppHeader,
  data: () => ({
    //
  }),
};
</script>
